<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Proxy Error Logs - Details</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="time" v-model="filter.start" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="time" v-model="filter.end" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <select class="form-control" v-model="filter.provider">
                                <option selected value="">--Any Provider--</option>
                                <option v-for="item in providers" :key="item" :value="item">{{item}}</option>
                            </select>
                        </div>

                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="reports"
                        :allowExcelExport='true'
                        :allowPaging="true"
                        :allowSorting="true"
                        :allowGrouping='true'
                        :allowFiltering="true"
                        :allowResizing='true'
                        :filterSettings="filterSettings"
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column field='date' minWidth='8' width='100' headerText="Date" format='yMd' type='date'></e-column>
                            <e-column field='time' minWidth='8' width='100' headerText="Time"></e-column>
                            <e-column field='provider' minWidth='8' width='110' headerText='Provider'></e-column>
                            <e-column field='campaign' minWidth='8' width='210' headerText='Camapign'></e-column>
                            <e-column field='ip' minWidth='8' width='180' headerText='Ip'></e-column>
                            <e-column field='country' headerText='Country' minWidth='8' width='110'></e-column>
                            <e-column field='state' headerText='State' minWidth='8' width='100'></e-column>
                            <e-column field='city' headerText='City' minWidth='8' width='100'></e-column>
                            <e-column field='port' minWidth='8' width='110' headerText='Port'></e-column>
                            <e-column field='method' headerText='Method' minWidth='8' width='100'></e-column>
                            <e-column field='error' headerText='Error Message' minWidth='8' width='800'></e-column>
                            <!-----                     <e-column field='username' headerText='User Name' minWidth='8' width='100'></e-column>
                                                 <e-column field='password' headerText='Password' minWidth='8' width='100'></e-column>---->
                            <!--                        <e-column field='proxy_ip' headerText='Proxy Ip' minWidth='8' width='100'></e-column>-->
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert";
import Vue from "vue";
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Group,
    Filter,
    Resize
} from "@syncfusion/ej2-vue-grids";
import pTemplate from "@/components/pTemplate";
import sha1 from "js-sha1";


Vue.use(GridPlugin);

export default {
    name: 'proxy.error.logs',
    props:['user'],
    components:{

    },
    data: function () {
        return {
            filter:{
                to: '',
                from: '',
                provider:"",
                type: "fail",
                time:'',
                client: '',
                product: '',
                start: "00:00",
                end: "23:59"
            },
            dropdownClients: [],
            clients: [],
            products: [],
            report:[],
            reports:[],
            providers:[

            ],

            timeSelector:[
                '12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'],
            proxyDataset: {},
            pageSettings: {pageSize: 100},
            groupSettings:  {
                showDropArea: false,
                disablePageWiseAggregates: true,
                columns: ['date', 'provider','time_frame']
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,
            pTemplate: function () {
                return {
                    template : pTemplate
                }
            }
        }
    },
    created: function(){
        this.load();
        this.loadProviders();

        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize
        ]
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        actionHandler: function(args) {
            console.log(args);
        },
        load: function () {
            //Load the campaign
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            const dateString = [year, month, day].join('-');
            this.filter.from = dateString;
            this.filter.to = dateString;
        },
        loadProviders: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/proxies`, {params:request}).then(function(resp){
                //Store the stats
                this.providers = (resp.data && resp.data.data)? resp.data.data : [];

            }.bind(this));
        },


        search: function(){
            this.searching = true;
            this.proxyDataset = [];
            this.report = [];
            this.reportLoading = true;

            var request = {'apikey': this.$root.apikey,  filters:{}};

            if(this.filter.from) request.filters.from = this.filter.from;
            if(this.filter.to) request.filters.to = this.filter.to;
            if(this.filter.provider) request.filters.provider = this.filter.provider;
            if(this.filter.time) request.filters.time = this.filter.time;
            if(this.filter.start) request.filters.start = this.filter.start;
            if(this.filter.end) request.filters.end = this.filter.end;

            axios.get(`${this.$root.serverUrl}/admin/reports/proxy/error-logs/detail`,{params:request}).then((resp) => {
                if(resp.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                }else{
                    const result = resp.data.data;
                    if(result.length > 0){
                        this.reports =[];
                        this.report =[];
                        for (let i in result) {
                            console.log(result[i])
                            this.report.push({
                                date:this.getDate(result[i].date),
                                time: this.getTime(result[i].date) /*this.getTimeFrame(result[i].date)*/,
                                provider:result[i].proxy.provider,
                                campaign:result[i].campaign_id,
                                ip:result[i].proxy.ip,
                                country:result[i].proxy.country,
                                state:result[i].proxy.state,
                                city:result[i].proxy.city,
                                port:result[i].proxy.port,
                                method:result[i].proxy.protocol,
                                error:result[i].message,
                                //username:result[i].username, password:result[i].password,
                            })
                        }
                        for (const item in this.report) this.reports.push(this.report[item]);
                       /* if(this.filter.time == "") { // check if the time is any empty string and dont not filter
                            for (const item in this.report) this.reports.push(this.report[item]);
                        }else{
                            for (const item in this.report)

                                if(this.report[item].time == this.filter.time)// filter is date match
                                    this.reports.push(this.report[item]);
                        }*/
                        this.showExportButton = true;
                        this.reportLoading = false;
                    }else{
                        this.reportLoading = false;
                        this.reports =[];
                    }
                }
                this.searching = false;
            }).catch((err) => {
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            });
        },

        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.reports,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US',{ timeZone: 'UTC'});
        },
        getTimeFrame: function(dateTime){
            const timeFrameMap = [
                '12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'
            ];
            let d = new Date(dateTime);
            return timeFrameMap[d.getHours()];
        },

        hashErrorTracking: function(geoHash,errorCode){
            return sha1(`${geoHash}${errorCode}`);
        }
    }
}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>


